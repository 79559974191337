import styled from 'styled-components'
import { mixins, colours } from 'styles'

export const SinglePageWrapper = styled.div`
  background-color: ${colours.white};
  padding-top: 2rem;
  padding-bottom: 2rem;
  .wrapper {
    ${mixins.innerWrapperMixin}
    ${mixins.textSection}
    h2 {
      font-size: 2rem;
      font-weight: bold;
      color: ${colours.greyOne};
    }
    ul {
      list-style: circle;
      margin: 1rem 0;
    }
  }
`
