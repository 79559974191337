import { graphql } from 'gatsby'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { LocalFileImage } from 'types'
import { getComponentFromRT, RichTextType } from 'helpers'
import { SinglePageWrapper } from 'components/layout/SinglePageWrapper'

const ContentWrapper = styled(SinglePageWrapper)`
  .gatsby-resp-image-wrapper {
    margin-bottom: 3rem;
  }
`

export interface PressKitPageProps {
  data: {
    page: {
      sectionOne: RichTextType
      pageImages: LocalFileImage[]
    }
  }
}

export const PressKitPage: FunctionComponent<PressKitPageProps> = ({ data }) => (
  <ContentWrapper>
    <div className="wrapper">
      <div>{getComponentFromRT(data.page.sectionOne)}</div>
      <div>
        {data.page.pageImages.map(({ fluid, title }) => (
          <Img
            key={title!}
            alt={title!}
            // only one image for this page
            fluid={fluid}
          />
        ))}
      </div>
    </div>
  </ContentWrapper>
)

export default PressKitPage

export const query = graphql`
  query PressKitQuery {
    page: contentfulPage(name: { eq: "presskit" }) {
      sectionOne {
        raw
      }
      pageImages {
        title
        fluid(maxWidth: 1170, quality: 75) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
